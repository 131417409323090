import React, { useContext, useState } from 'react'
import styled from 'styled-components';

import { FaSearch } from "react-icons/fa";
import { FiltersContext } from "../../../../contexts/filters"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .clearbutton{
        margin-top: 0.5vh;
        font-family: BebasNeue;
        font-size: 14px;
        color: black;


        &:hover{
            text-decoration: underline;
            cursor: pointer;
        }
    }

    @media(max-width: 1000px){
        width: 100%;
    }
`;

const BoxWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    //position: relative;

    width: 100%;
    /* @media (max-width: 1000px){
        width: 80vw;
    } */

    background: white;
    border-radius: 3px;

    padding-right: 15px;

    .searchbar{
        width: 100%;
        background: white;
        outline: none;
        border: none;
        font-size: max(calc(0.5vh + 0.5vw), 14px);

        padding: 1.5vh 2vh 1.5vh 2vh;
        //border: 1px solid rgba(109, 110, 113, 0.5);
        border-radius: 3px;
    }

    .submit{
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
        /* position: absolute;
        right: 2.5%; */

        color: rgba(109, 110, 113, 0.5);

        border: none;
        background: none;
    }
`;



const SearchBarView = () => {
    const [value, setValue] = useState('')
    const {onSearch} = useContext(FiltersContext)

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          onSearch(value)
        }
    }

    const onChangeValue = (e) => {
      setValue(e.currentTarget.value)
    }

    const onClearSearch = () => {
      setValue('')
      onSearch('')
    }

    return(
        <Wrapper>
            <BoxWrapper>
                <input
                    placeholder="Search Products"
                    className="searchbar"
                    type="text"
                    value={value}
                    onChange={onChangeValue}
                    onKeyDown={(event) => handleKeyDown(event)}
                />
                <button
                    type="submit"
                    value="Submit"
                    className="submit" 
                    onClick={() => onSearch(value)}
                >
                    <FaSearch/>
               </button>
            </BoxWrapper>

            <button
              className="clearbutton"
              onClick={onClearSearch}
            >
                Reset search
            </button>

        </Wrapper>
    )
}

export default SearchBarView;