import React, { useEffect, useState } from "react"
import RCPagination from "rc-pagination";
import enUsLocale from "rc-pagination/lib/locale/en_US";

function PaginationView({pagination, productsCount, onChangePagination, pageSize = 20}) {
    const [currentPage, setCurrentPage] = useState(1)


    const handlePageChange = (page) => {
        const offset = (page - 1) * pageSize
        setCurrentPage(page)
        const pagination = {offset, limit: pageSize}
        onChangePagination(pagination);
    };

    useEffect(() => {
        const { offset, limit } = pagination;
        const newCurrentPage = Math.floor(offset / limit) + 1;
        setCurrentPage(newCurrentPage);
    }, [pagination]);

    return (
        <RCPagination
            current={currentPage}
            onChange={handlePageChange}
            pageSize={pageSize}
            total={productsCount}
            className={"sui-paging"}
            locale={enUsLocale}
        />
    )
}

export default PaginationView;