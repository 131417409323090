import React from 'react';
import styled from 'styled-components'

import { MOBILE_BREAKPOINT } from '../../styles/breakpoints';

const Wrapper = styled.button`
    width: 100%;

    display: flex;
    justify-content: center;
`;

const StyledButton = styled.button`
    background-color: var(--darkgreen);
    color: white;
    border-radius: 30px;
    border: none;
    padding: 5px 35px;
    margin: 5px 0px;
    font-family: IBM Plex Mono Medium;
    font-size: 17px;
    font-weight: 900;

    grid-column: 2;

    &:hover{
        cursor: pointer;
        background-color: #568278;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}){
        padding: 10px 50px;
    }
`;

export default function ClearFilterButton({ clearAllFilters })
{
    return(
        <Wrapper>
            <StyledButton
                onClick={clearAllFilters}
            >
                
                Clear All
            </StyledButton> 
        </Wrapper>
    )
}