import React, { useContext, useEffect } from 'react'
import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from '../../styles/breakpoints';

//SEO
import SeoPicker from '../templates/Seo/SeoPicker';

//Top
import SearchBarView from '../elasticsearch/menu/view/SearchBarView';
import BreadCrumbs from './Top/Breadcrumbs';
import SortBy from './Top/SortBy';

//LeftSide (Filters)
import MenuDesktopFilter from './filters/MenuDesktopFilter';
import MenuMobileFilter from './filters/MenuMobileFilter';

//Toastify Components
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Products from "./products/Products"
import { FiltersContext } from "../../contexts/filters"
import Pagination from "./Others/Pagination"

const PageWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    background-color: var(--lightgreen);
    padding-top: 10vh;
    position: relative;
`;

const CenterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 30px;

    @media (max-width: 1200px) {
        width: 70%
    }
    
    @media (max-width: 768px) {
        width: 100%;
        padding: 0 20px;
    }
`

const Top = styled.div`
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-right: 0;

    @media (min-width: 769px) {
        padding-right: 20px;
    }

    .searchbox{
        width: 100%;
        margin-bottom: 2vh;
    }

    .disclaimer {
        width: 100%;
        background: #F4FBF9;
        margin: 0 auto;
        margin-top: 1.5vh;
        display: flex;
        justify-content: center;
        padding: 0 20px;
        .content {
            font-size: 11px;
            font-family: "IBM Plex Mono SemiBold";
            color: #404E4B;
        }
    }

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        width: 100%;

        position: static;

        flex-direction: column;
        padding-top: 10px;
        //padding-bottom: 20px;
        justify-content: center;

        .hidemobile{
            display: none;
        }
        .searchbox{
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .disclaimer {
            width: 100%;
            margin: 0;
        }
    }
`;

const LeftSide = styled.div`
    width: 20%;
    margin-top: 30px;
    
    @media (max-width: 1200px) {
        width: 30%
    }

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        margin-top: 0;
        width: 100%;
    }
`;

const PaginationWrapper = styled.div`
  margin: 65px 5px 35px 5px;
`;


const MenuHub = ({prerenderdata, filterdata}) => {

    const {data, loading, price, onChangeCategories, filters: {pagination}, onChangePagination} = useContext(FiltersContext)
    const products = data?.menu?.products
    const filteredProducts = products?.filter(item => item?.variants[0]?.priceMed >= price.min && item?.variants[0]?.priceMed <= price.max)
    const productsCount = data?.menu?.productsCount

    useEffect(() => {
        onChangeCategories(prerenderdata)
    }, [prerenderdata])

    return(
        <>
        <SeoPicker prerenderdata={prerenderdata} cmsCategoryData={filterdata}/>
        <PageWrapper>


            <LeftSide>
                <MenuDesktopFilter prerenderdata={prerenderdata} cmsCategoryData={filterdata}/>
                <MenuMobileFilter cmsCategoryData={filterdata} />
            </LeftSide>

            <CenterWrapper>
                <Top>
                    <div className='searchbox'><SearchBarView/></div>
                    <div className="hidemobile"><BreadCrumbs opencat={prerenderdata?.category} opensubcat={prerenderdata?.subcategory}/></div>
                    <div className="hidemobile"><SortBy/></div>
                    <div className='disclaimer'>
                        <p className='content'><br></br><br></br>

                            *Orders ready within 15 mins. Please come by or call if you haven't received a text.<br></br><br></br>
                            *You must have your ID and credit card ready for verification. <br></br><br></br>
                            *Please have Government-issued ID & Payment Card used available when picking up.<br></br><br></br>
                            *ID NAME AND ORDER NAME MUST MATCH. <br></br><br></br><br></br>
                        </p>
                    </div>
                </Top>
                <Products products={filteredProducts} loading={loading}/>
                <PaginationWrapper>
                    {productsCount > pagination.limit? <Pagination onChangePagination={onChangePagination} pagination={pagination} productsCount={productsCount}/> : ''}
                </PaginationWrapper>
            </CenterWrapper>
        </PageWrapper>
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            newestOnTop={false}
            closeOnClick={true}
            pauseOnHover={false}
        />
        </>
    )
};
export default MenuHub