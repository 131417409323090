import React, { useEffect } from 'react';
import styled from 'styled-components';
import exitX from '../../../assets/images/x.svg';

const Background = styled.div`
    position: fixed;
    top: 95px;
    right: 0;
    width: 100%;
    opacity: 0;
    transition: all 0.4s ease;
    
    &.is-open{
        height: 100%;
        right: 0;
        background: black;
        opacity: 0.7;
        z-index: 4;
    }
`;

const SideSlideMenu = styled.div`
    position: fixed;
    top: 95px;
    right: -100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 80%;
    height: calc(100% - 50px);
    padding: 0px;
    opacity: 1;
    transition: all 0.5s ease;

    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    &.is-open{
        right: 0;
        z-index: 5;
        background: var(--lightgreen);
        opacity: 1;
        transition: all 0.5s ease;
    }
    
    .top-area{
        display: flex;
        justify-content: flex-end;
        margin: 20px 16px;
    }
`;

export default function MobileSideFilters({toggle, setToggle, children}){
    //disable scroll
    useEffect(() => {
        if (toggle) {
            document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
            document.getElementsByTagName('body')[0].style.overflowY = 'hidden';

            document.getElementsByTagName('html')[0].style.position = 'fixed';
            document.getElementsByTagName('body')[0].style.position = 'fixed';
        }
    }, [toggle]);

    //enable scroll
    useEffect(() => {
        if (!toggle) {
            document.getElementsByTagName('html')[0].style.overflowY = '';
            document.getElementsByTagName('body')[0].style.overflowY = '';

            document.getElementsByTagName('html')[0].style.position = 'static';
            document.getElementsByTagName('body')[0].style.position = 'static';
        }
    }, [toggle]);

    return(
        <>
        <Background className={toggle ? "is-open" : ""} 
            onClick={(e) => {
                e.preventDefault();
                setToggle(false)} 
            }
            onTouchStart={(e) => e.preventDefault()}
        />
        <SideSlideMenu className={toggle ? "is-open" : ""}>
            <div className="top-area"><img src={exitX} onClick={() => setToggle(false)}/></div>
            {children}
        </SideSlideMenu>
        </>
    );
}