import React from 'react';
import styled from 'styled-components';
import { Link } from "gatsby"

const CategoryFilterStyle = styled.div`
    font-family: 'IBM Plex Mono Regular';

    .subcatTitle {
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: var(--darkgreen);
        padding: 5px 5px;
        padding-left: 12px;
        letter-spacing: 1px;
        &:hover {
            text-decoration-line: underline;
            color: #2d2b2c;
            cursor: pointer;
        }
    }
    .close {
        display: none;
    }
    .open {
        display: block;
    }

    .focused {
        text-decoration: underline;
        color: #2d2b2c;
    }
`;

export default function SubCategoryFilter({ parent, setCurrentSubCategory, setActiveSubCategory }) {
    
    return parent.SubCategories && parent.SubCategories.length > 0 ? (
        <CategoryFilterStyle>
            {parent.SubCategories.map((child) => {
                return (
                    <Link
                        to={`/menu/${parent?.Slug.toLowerCase()}/${child?.Slug.toLowerCase()}`}
                        key={child.Slug}
                        className={`subcatTitle`}
                        onClick={() => {}}
                    >
                        {child.Name}
                    </Link>
                )})}
        </CategoryFilterStyle>
        ) : (
        <>
        </>
    );

}