import React, { useState } from 'react';
import styled from 'styled-components';
import MyCollapse from '../../MyCollapse';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CheckoutContext } from '../../../contexts/checkout-context';
import { MOBILE_BREAKPOINT } from '../../../styles/breakpoints';
import {Link} from "gatsby";

const MenuWrapper = styled.ul`
  margin: 10px 0;
  .react-sanfona-item-title{
    font-size: 20px;
    font-family: 'IBM Plex Mono Bold';
    padding: 5px 0;
    display: block
  }
  ul {
    margin-left: 10px;
    margin-bottom: 15px;
  }
`;
const MenuFilterWrapper = styled.div`
    display: none;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        display: block;
    }
    margin: 5px;
    overflow-x: hidden;
    padding: 10px 15px;
    color: black;
    border-radius: 10px;
    border: solid 4px var(--limegreen);
`;

const CategoryWrapper = styled.div`
    margin: 10px 0;
    width: 100%;

    .dd-category {
        font-family: 'Druk Wide Medium';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        color: var(--darkgreen);
        margin: 18px 0;
        width: 100%;
        padding-left: 18px;
    }
`;

const SubCategoryWrapper = styled.div`
    .swiper-subcat {
        z-index: 0;
    }
    .subcat-block-card {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--lightgreen);
        border: 1px solid var(--darkergreen);;
        box-sizing: border-box;
        border-radius: 3px;
        padding: 11px 2px;
        font-family: 'IBM Plex Mono Regular';
        font-style: normal;
        font-weight: 500;
        letter-spacing: 1px;
        font-size: 13.5px;
        color: var(--darkergreen);
        &.selected {
            background: var(--green);
        }
    }
    
    .swiper-slide{
        width: max-content;
        padding: 5px 10px;
    }
`;

export default function MobileCategoryFilters({cmsCategoryData}){
    const catdata = cmsCategoryData.Categories;

    const [categoryExpand, setCategoryExpand] = useState(false);
    const hasAnyResults = (facets, slug) => {
        return getResultCount(facets, slug) > 0;
    };

    const getResultCount = (facets, slug) => {
        if (facets && facets.subcategory && facets.subcategory.length > 0) {
        const facet = facets.subcategory[0];
        if (facet.data) {
            const matches = facet.data.filter((x) => x.value === slug);
            if (matches.length > 0) return matches[0].count;
        }
        }

        return 0;
    };

    return(
        <>
        <CheckoutContext.Consumer>
        {({ activeCategory, setActiveCategory, setActiveSubCategory, activeSubCategory }) => {
        return(
          <MenuFilterWrapper>
              <CategoryWrapper>
                  <MyCollapse
                    title={
                        (activeCategory && activeCategory!='all') ?
                          catdata[catdata.findIndex((cat) => cat.Slug === activeCategory)].Name
                          :
                          "Pick a category..."
                    }
                    expanded={categoryExpand}
                    setExpanded={setCategoryExpand}
                  >
                      <MenuWrapper>
                          {catdata.map(category => {
                              return(
                                  <li key={category.Name}>
                                      <Link
                                          to={`/menu/${category?.Slug.toLowerCase()}`}
                                          key={category.Slug}
                                          className={'react-sanfona-item-title'}
                                          onClick={() => {
                                          }}
                                      >
                                          {category.Name}
                                      </Link>
                                      {category.SubCategories.length > 1 && (
                                          <ul>
                                              {category.SubCategories.map((subCat) => {
                                                return(
                                                    <li key={subCat.Name}>
                                                        <Link
                                                            to={`/menu/${category?.Slug.toLowerCase()}/${subCat?.Slug.toLowerCase()}`}
                                                            className={`subcatTitle`}
                                                            onClick={() => {}}
                                                        >
                                                            {subCat.Name}
                                                        </Link>
                                                    </li>
                                                )
                                          })}
                                          </ul>
                                      )}
                                  </li>
                              )
                          })}
                      </MenuWrapper>
                  </MyCollapse>
              </CategoryWrapper>

              <SubCategoryWrapper>
                  {(activeSubCategory && activeCategory!="all")
                    &&

                    <Swiper
                      className="swiper-subcat"
                      spaceBetween={10}
                      slidesPerView={'auto'}
                      freeMode={true}
                      watchOverflow={true}
                      freeModeMomentum={true}
                    >
                        {catdata[catdata.findIndex((cat) => cat.Slug === activeCategory)].SubCategories.map(
                          (subCategory, index) => {
                              return <SwiperSlide
                                key={subCategory.Slug}
                                virtualIndex={index}
                                onClick={() => {}}
                                className={
                                    subCategory.Slug === activeSubCategory
                                      ? 'subcat-block-card selected'
                                      : 'subcat-block-card'
                                }
                              >
                                  {subCategory.Name}
                              </SwiperSlide>
                          }
                        )}
                    </Swiper>
                  }
              </SubCategoryWrapper>
          </MenuFilterWrapper>
        )
        }}
        </CheckoutContext.Consumer>
        </>
    )
}