import React, { useContext } from 'react'
import styled from 'styled-components';

import PaginationView from '../../elasticsearch/menu/view/PaginationView';
import { FiltersContext } from "../../../contexts/filters"

const PagingWrap = styled.div`
  .rc-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .rc-pagination-item, .rc-pagination-jump-next, .rc-pagination-next, .rc-pagination-prev {
    background: #ffffff;
    border: 1px solid rgba(68, 105, 97, 0.3);
    box-sizing: border-box;
    border-radius: 2px;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 35px;
    width: 35px;

    margin: 0px 8px;

    a {
      font-family: IBM Plex Mono Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: var(--green);
      transition: 250ms color;
    }

    &:hover {
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 2px;

      a {
        color: var(--darkgreen);
      }

      .rc-pagination-item-link{
        color: var(--darkgreen);
       }
    }
  }

  .rc-pagination-next,
  .rc-pagination-prev {
    border: none !important;
    background-color: transparent !important;
    .rc-pagination-item-link {
      background-color: transparent ;
      border: none;
      font-size: 30px;
    }
    &:not(.rc-pagination-disabled) {
      .rc-pagination-item-link {
        color: var(--darkgreen);
        
        &:hover {
          color: var(--darkestgreen);
        }
      }
    }
  }

  .rc-pagination-item-link{
      border-radius: 0;
      border: none;
      color: #c4c4c4;
      transition: 250ms color;
      display: flex;
        justify-content: center;
        align-items: center;
    }

  .rc-pagination-item-active {
    background: #ffffff;
    border: 1px solid var(--darkgreen);
    box-sizing: border-box;
    border-radius: 2px;
    a {
      color: var(--darkgreen);
    }
  }

  .rc-pagination-disabled{
    border: 1px solid grey;

    &:hover{
        border: 1px solid grey;
    }
  }

  @media(max-width: 1000px){
    .rc-pagination-item, .rc-pagination-jump-next, .rc-pagination-next, .rc-pagination-prev {
        margin: 0 3px;
        height: 25px;
        width: 25px;
    }
  }
`;

const Pagination = ({productsCount, pagination, onChangePagination}) => {

  return (
    <PagingWrap>
      <PaginationView pageSize={20} productsCount={productsCount} onChangePagination={onChangePagination} pagination={pagination}/>
    </PagingWrap>
  )
}

export default Pagination;
