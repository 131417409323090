import React from "react"
import { graphql } from "gatsby"

import MenuHub from "../components/menu/MenuHub"
import MainLayout from "../components/layouts/MainLayout"



export default function Home({ data, pageContext }) {

  const prerenderdata = {
    category: pageContext.category,
    subcategory: pageContext.subcategory,
  }

  return (
    <MainLayout>
      <MenuHub
        filterdata={data?.filterCategories}
        prerenderdata={prerenderdata}
      />
    </MainLayout>
  )
}

export const query = graphql`
  query {
    filterCategories: strapiFilterSettings {
      Categories {
        Slug
        Name
        MetadataTitle
        MetadataDescription
        SubCategories {
          Name
          Slug
          MetadataTitle
          MetadataDescription
        }
      }
    }
  }
`
