import React, { useState } from 'react';
import styled from 'styled-components';
import { Accordion, AccordionItem } from 'react-sanfona';

import { CheckoutContext } from '../../../contexts/checkout-context';

import SubCategoryFilter from './SubCategoryFilter';
import {Link} from "gatsby";

const Wrapper = styled.div`
    width: 100%;
    padding: 2vh 0;
    padding-bottom: 0;

    border-top: 1px solid rgba(147, 172, 166, 0.5);

    .react-sanfona-item{
        //margin-left: 2vh;
        padding-top: 2vh;
    }

    .react-sanfona-item-title{
        font-family: 'IBM Plex Mono Bold';
        text-transform: capitalize;
        font-weight: 500;
    }

    .react-sanfona-item-body-wrapper{
        margin-top: 1vh;
        width: 100%;
    }

    .active{
        color: var(--green);
    }

    .subcategory{
        color: black;
        cursor: pointer;
        margin: 1vh 0;
    }
    .dropdownTitle:before {
    display: inline-block;
    content: '';
    height: 8px;
    width: 8px;
    margin-right: 7px;
    border-bottom: 1.75px solid var(--darkgreen);
    border-right: 1.75px solid var(--darkgreen);
    transform: rotate(-135deg);
  }

  .react-sanfona-item-title:not(.single):before {
    display: inline-block;
    content: '';
    height: 8px;
    width: 8px;
    margin-right: 7px;
    border-bottom: 1.75px solid var(--darkgreen);
    border-right: 1.75px solid var(--darkgreen);
    transform: rotate(-135deg);
  }

  .react-sanfona-item-title[aria-expanded='true']::before,
  .react-sanfona-item-title[aria-selected='true']::before {
    transform: rotate(45deg);
  }

  .react-sanfona-item-title[aria-expanded='true'],
  .react-sanfona-item-title[aria-selected='true']{
    text-decoration: underline;
  }
  .react-sanfona-item-title:hover{
    text-decoration: underline;
  }
`;

const ScrollWrapper = styled.div`
    max-height: 350px;
    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: #A2C7B3 #C3D4CF;
    &::-webkit-scrollbar {
        width: 7px;
        background: #C3D4CF;
        //border-radius: 5px;
        transition: 250ms;
    }

    &::-webkit-scrollbar-thumb{
        background-color: #A2C7B3;
        //border-radius: 10px;

        &:hover{
            background-color: #89a194;
        }

        &:active{
            background-color: #59806b;
        }
    }

`;

export default function CategoryFilters({
    prerenderSubcategory,
    cmsCategoryData
})
{
    const [currentSubCategory, setCurrentSubCategory] = useState();

    return(
        <Wrapper>
            <ScrollWrapper>
            <CheckoutContext.Consumer>
                {({ activeCategory, setActiveCategory, setActiveSubCategory }) => {
                    return(

                      <Accordion>
                          {cmsCategoryData.Categories.map(category => {
                              if (!category.SubCategories.length || category.SubCategories.length <= 1) {
                                  return (
                                      <h3 className={'react-sanfona-item'}>
                                      <Link
                                          to={`/menu/${category?.Slug.toLowerCase()}`}
                                          key={category.Slug}
                                          className={'react-sanfona-item-title single'}
                                          onClick={() => {
                                          }}
                                      >
                                          {category.Name}
                                      </Link>
                                      </h3>
                                  )

                              } else {
                                  return (
                                      <AccordionItem
                                          key={category.Slug}
                                          title={category.Name}
                                      >
                                          <SubCategoryFilter
                                              parent={category}
                                              prerenderSubcategory={prerenderSubcategory}
                                              currentSubCategory={currentSubCategory}
                                              setCurrentSubCategory={setCurrentSubCategory}
                                              setActiveSubCategory={setActiveSubCategory}

                                          />
                                      </AccordionItem>
                                  )
                              }
                          })}
                      </Accordion>
                    )
                }}
            </CheckoutContext.Consumer>
            </ScrollWrapper>
        </Wrapper>
    )
}