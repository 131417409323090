import React, {useState, useEffect, createRef} from 'react';
import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from '../../../styles/breakpoints';
import slider from '../../../assets/images/Slider.svg';

import MobileSideFilters from './MobileSideFilters';
import SecondaryFilters from '../../elasticsearch/menu/SecondaryFilters';
import MobileCategoryFilters from '../../elasticsearch/menu/MobileCategoryFilters';

const Wrapper = styled.div`
    @media (min-width: ${MOBILE_BREAKPOINT}){
        display: none;
    }
`;

const CategoryWrapper = styled.div`
    margin: 20px 15px;
    margin-bottom: 30px;
    border-radius: 3px;
    opacity: 1;

    @media (max-width: ${MOBILE_BREAKPOINT}){
        margin: 0 15px;
    }
`;

const FilterButton = styled.div`
    background: white;
    border: var(--darkgreen) solid 1px;
    color: var(--darkgreen);

    padding: 10px 15px;
    margin: 15px 20px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-family: IBM Plex Mono Regular;

    position: relative;

    .btnlabelwrapper{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--darkgreen);
    }
    .btnlabel{
        font-weight: 600;
    }

    .btnimg{
        position: absolute;
        left: 5%;
    }
`;


export default function MenuMobileFilter({ cmsCategoryData }){
    const [toggle, setToggle] = useState(false);

    const cartRef = createRef();
    //disable scroll
    useEffect(() => {
        if (toggle) {
            document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
        }
    }, [toggle]);

    //enable scroll
    useEffect(() => {
        if (!toggle) {
            document.getElementsByTagName('html')[0].style.overflowY = '';
        }
    }, [toggle]);


    const onHandleClick = (e) => {
        e.preventDefault();
        setToggle(true);
        // console.log('clicked')
    }

    return(
        <Wrapper>

            <CategoryWrapper>
                <MobileCategoryFilters cmsCategoryData={cmsCategoryData}/>
            </CategoryWrapper>

            <FilterButton onClick={onHandleClick}>
                
                <div className="btnlabelwrapper">
                    <img className="btnimg" src={slider}/>
                    <div className="btnlabel">Filter and Sorting</div>
                </div>
            </FilterButton>

            <MobileSideFilters toggle={toggle} setToggle={setToggle}>
                <SecondaryFilters includeSort/>
            </MobileSideFilters>
        </Wrapper>
    )
}