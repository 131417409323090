import React from "react"

const RadioCheckbox = ({ item, onClick, checked}) => {
	return (
		<div className="pretty p-default" style={{margin: "0 0 10px", width: "100%"}}>
			<input type="checkbox" id={item?.value} checked={checked} onClick={() => onClick(item.value)}/>
			<div className="state">
				<label>{item?.label}</label>
			</div>
		</div>
	)
}

export default RadioCheckbox