import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"


const Wrapper = styled.div`
  display: flex;
  align-items: center;

  width: 75%;

  font-family: IBM Plex Mono Regular;
  color: var(--darkgreen);

  text-transform: capitalize;

  white-space: pre;

  .item {
    padding: 5px;
    border-radius: 10px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  p {
    margin: 0;
  }

  @media (max-width: 1000px) {
    width: 90%;
    font-size: 1.75vh;
  }
`

const BreadcrumbListContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Crumb = styled.div`
  cursor: default;
  border: none;
  position: relative;

  &.clickable {
    cursor: pointer;
    color: var(--darkgreen);
    font-weight: 400;
    &:hover {
      color: var(--darkergreen);
      font-weight: 600;
    }
  }
`

const Space = styled.div`
  margin: 0 8px;
  display: inline-block;
  border-bottom: 1px solid transparent;
`

export default function BreadCrumbs({ opencat, opensubcat }) {
  const Seperator = () => <Space>{">"}</Space>

  const BreadcrumbList = ({ filters, clearFilters, removeFilter }) => {
    // console.log("--------BREADCRUMBLIST RERENDER============")
    // let category
    // let subcategory
    //
    // const data = useStaticQuery(graphql`
    //   query {
    //     strapiFilterSettings {
    //       Categories {
    //         Name
    //         Slug
    //         SubCategories {
    //           Name
    //           Slug
    //         }
    //       }
    //     }
    //   }
    // `)

    // const subCategories = data.strapiFilterSettings.Categories.map(
    //   x => x.SubCategories
    // ).flat()
    // // console.log("subCategories: ")
    // // console.log(subCategories)
    // const categories = data.strapiFilterSettings.Categories.map(x => ({
    //   Name: x.Name,
    //   Slug: x.Slug,
    // }))
    // const mapSubcategory = slug => subCategories.find(x => x.Slug == slug).Name
    // const mapCategory = slug => categories.find(x => x.Slug === slug).Name

    return (
      <Wrapper>
        <Link to="/mainmenu">
          <p className="item">
            {'All Items'}
          </p>
        </Link>

        <p>
          {opencat ? '>' : ''}
        </p>

        {opencat && <Link to={`/menu/${opencat?.toLowerCase()}`}>
          <p className="item">
            {opencat.toLowerCase().replace("_", " ")}
          </p>
        </Link>}

        <p>
          {opensubcat ? '>' : ''}
        </p>

        {opensubcat && <Link to={`/menu/${opencat?.toLowerCase()}/${opensubcat?.toLowerCase()}`}>
          <p className="item">
            {opensubcat ? opensubcat?.toLowerCase().replace("_", " ") : ''}
          </p>
        </Link>}
      </Wrapper>
    )
  }

  return (
    <BreadcrumbList />
  )
}
