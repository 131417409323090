import { useQuery } from "@apollo/client"
import { GET_ALL_SECONDARY_FILTERS } from "../queries/query"


export const useGetAllSecondaryFilters = () => {
	const variables = {
		retailerId: "f8228e44-bcad-4f20-a0ab-d1dea02f71b8"
	}

	return useQuery(GET_ALL_SECONDARY_FILTERS, {
		variables
	})
}