import ProductCard from "../product/ProductCard"
import React from "react"
import styled from "styled-components"
import NoResultsView from "../../elasticsearch/menu/view/NoResultsView"
import Loader from "../Others/Loader"

const Products = ({products, loading}) => {

	if(loading) return <Loader/>
	if(!products || products.length === 0) return <NoResultsView/>

	return (
		<Center>
			{products?.map(product => {
				return (
					<ProductCard key={product.id} product={product}/>
				)
			})}
		</Center>
	)
}

export default Products

const Center = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    padding-right: 0;
    gap: 10px;

  	@media (min-width: 510px) {
      grid-template-columns: repeat(2, 1fr);
	  }

    @media (min-width: 769px) {
        padding-right: 20px;
        gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    }
`;