import React, { useState, useRef } from 'react';
import { Children } from 'react';
import styled from 'styled-components';

const DropdownWrapper = styled.div`
    width: 100%;
    display: block;

    .dd-title h3{
        color: var(--darkgreen);
        font-family: 'Druk Wide Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        display: inline-block;
    }

    .dd-title:before{
        display: inline-block;
        content: '';
        height: 12px;
        width: 12px;
        margin-right: 10px;
        margin-bottom: 3px;
        border-bottom: 3px solid var(--darkgreen);
        border-right: 3px solid var(--darkgreen);
        transform: rotate(-135deg);
    }

    .dd-title[aria-expanded='true']::before,
    .dd-title[aria-selected='true']::before {
        transform: rotate(45deg);
    }
`;

const DropdownContentWrapper = styled.div`
`;

const DropdownContent = styled.div`
    .content{
        height: 0px;
        overflow: hidden;
        transition: height ease 0.3s;
        color: var(--darkgreen);

        hr{
            opacity: 0.1;
            color: white;
            width: 100%;
        }
    }

    .dd-open{
        height: ${props => props.expanded ? '425px' : '0px'};
        overflow-y: auto;
    }

`;

export default function MyCollapse({title, expanded, setExpanded, children}){
    
    const contentHeight = useRef();
    
    return(
        <DropdownWrapper>
            <div className="dd-title" aria-expanded={expanded} onClick={() => {setExpanded(!expanded)}}>
                <h3>{title}</h3>
            </div>
            <DropdownContentWrapper>
                <div className="refWrapper" ref={contentHeight}>
                    <DropdownContent contentHeight={contentHeight} expanded={expanded}>
                        <div className={expanded ? "dd-open content" : "content"}>
                            {children}
                        </div>
                    </DropdownContent>
                </div>
            </DropdownContentWrapper>
        </DropdownWrapper>
    )
}