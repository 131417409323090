import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { Accordion, AccordionItem } from 'react-sanfona';

import SliderFilterPicker from '../../menu/Others/SliderFilterPicker';

import PriceSliderFilter from './PriceSliderFilter';

import Sort from '../../menu/Top/SortBy';

import { MOBILE_BREAKPOINT } from '../../../styles/breakpoints';
import ClearFilterButton from '../ClearFilterButton';
import { useGetAllSecondaryFilters } from "../../../hooks/useGetSecondaryFilters"
import Select from "react-select"
import RadioCheckbox from "../../inputs/radioCheckbox"
import CustomCheckbox from "../../inputs/customCheckbox"
import ThcSlideFilter from "./ThcSlideFilter"
import CbdSlideFilter from "./CbdSlideFilter"
import { FiltersContext } from "../../../contexts/filters"

const Wrapper = styled.div`
    //margin-top: 15px;
    .sortwrapper{
        //margin-left: 2vh;
        //width: calc(100% - 4vh);
        /* display: flex;
        justify-content: center;
        align-items: center; */
        margin-bottom: 35px;
    }
    width: 100%;

    .react-sanfona-item{
        border-top: 1px solid rgba(177, 178, 179, 0.5);
        margin: 2vh 0;
        padding-top: 2vh;
    }

    .react-sanfona-item-title{
        font-family: IBM Plex Mono Bold;
        text-transform: uppercase;
        font-weight: 500;
        color: var(--darkgreen);
    }

    .react-sanfona-item-body-wrapper{
        margin-top: 2vh;
    }

    @media (max-width: ${MOBILE_BREAKPOINT}){
        width: 90%;
        margin-left: 5%;
        padding-bottom: 5vh;

        .react-sanfona-item{
            background-color: white;
            padding: 10px 8px;
            border-radius: 4px;
            border-top: 0px;
        }
    }
`;

const ClearButton = styled.div`
  font-family: IBM Plex Mono Medium;
  color: black;
  font-size: 14px;
  display: block;
  max-width: max-content;
  margin: 5px auto 0;

  &:hover{
    text-decoration: underline;
    cursor: pointer;
  }
`;

const strainTypes = [
    {value: "HIGH_CBD", label: "High Cbd"},
    {value: "HYBRID", label: "Hybrid"},
    {value: "INDICA", label: "Indica"},
    {value: "NOT_APPLICABLE", label: "Not applicable"},
    {value: "SATIVA", label: "Sativa"}
]

const weights = [
    {value: "1g", label: "1g"},
    {value: "3.5g", label: "3.5g"},
    {value: "7g", label: "7g"},
    {value: "14g", label: "14g"},
    {value: "15g", label: "15g"},
    {value: "28g", label: "28g"}
]

const effects = [
    {value: "CALM", label: "Calm"},
    {value: "CLEAR_MIND", label: "Clear mind"},
    {value: "CREATIVE", label: "Creative"},
    {value: "ENERGETIC", label: "Energetic"},
    {value: "FOCUSED", label: "Focused"},
    {value: "HAPPY", label: "Happy"},
    {value: "INSPIRED", label: "Inspired"},
    {value: "RELAXED", label: "Relaxed"},
    {value: "SLEEPY", label: "Sleepy"},
    {value: "UPLIFTED", label: "Uplifted"},
]

const SecondaryFilters = ({includeSort}) => {
    const selectInputRef = useRef(null);

    const {
        onChangeBrand,
        onChangeStrainType,
        onChangeWeights,
        onChangeEffects,
        filters,
        clearAllFilters,
        onChangeMinMaxThc,
        onChangeMinMaxCbd,
        onChangePrice,
    } = useContext(FiltersContext)

    useEffect(() => {
        filters.brandId === undefined && selectInputRef.current.select.clearValue()
    }, [filters.brandId])
    const [minMaxThc, setMinMaxThc] = useState([0, 100])
    const [minMaxCbd, setMinMaxCbd] = useState([0, 100])

    const {data, loading} = useGetAllSecondaryFilters()

    const brands = data?.menu.brands

    const handleClear = () => {
        setMinMaxThc([0, 100])
        setMinMaxCbd([0, 100])
        clearAllFilters()
    }

    return (

      <Wrapper>
          {includeSort && <div className="sortwrapper"><Sort/></div>}

          <Accordion allowMultiple={true}>
              <AccordionItem
                title="Price (CAD)"
              >
                  <PriceSliderFilter
                    prefix="$"
                    throttler
                    filterType={"price"}
                    onChangePrice={onChangePrice}
                  />
              </AccordionItem>

              <AccordionItem
                title="THC"
              >
                  <ThcSlideFilter
                    onChangeMinMaxThc={onChangeMinMaxThc}
                    throttler
                    minMaxThc={minMaxThc}
                    setMinMaxThc={setMinMaxThc}/>
              </AccordionItem>

              <AccordionItem
                title="CBD"
              >
                  <CbdSlideFilter
                    onChangeMinMaxCbd={onChangeMinMaxCbd}
                    throttler
                    minMaxCbd={minMaxCbd}
                    setMinMaxCbd={setMinMaxCbd}
                  />
              </AccordionItem>

              <AccordionItem title="Brand">
                  <Select
                    ref={selectInputRef}
                    placeholder={"Select..."}
                    classNamePrefix="react-select-filter"
                    onChange={option => onChangeBrand(option)}
                    options={brands?.map((brand) => ({ value: brand.id, label: brand.name }))} />
                  <ClearButton
                    onClick={ () =>{
                        onChangeBrand('clear')
                    }}
                  >
                      Clear
                  </ClearButton>
              </AccordionItem>

              <AccordionItem title="Strain Type">
                  {strainTypes?.map((item) => {
                      return (
                        <RadioCheckbox
                          key={item.value}
                          item={item}
                          checked={filters.strainType === item.value}
                          onClick={onChangeStrainType}/>
                      )
                  })}
              </AccordionItem>

              <AccordionItem title="Weight">
                  <div style={{display: "flex", flexWrap: "wrap", gap: "10px"}}>
                      {weights?.map((item) => {
                          return (
                            <CustomCheckbox
                              key={item.value}
                              item={item}
                              onClick={onChangeWeights}
                              checked={
                                  filters.weights
                                    ? filters.weights?.includes(item.value)
                                    : false
                            }
                              customStyles={"customCheckbox"}/>
                          )
                      })}
                  </div>
              </AccordionItem>

              <AccordionItem title="Effects">
                  <div style={{display: "flex", flexWrap: "wrap", gap: "10px"}}>
                      {effects?.map((item) => {
                          return (
                            <CustomCheckbox
                              key={item.value}
                              item={item}
                              checked={
                                  filters.effects
                                    ? filters.effects?.includes(item.value)
                                    : false
                              }
                              onClick={onChangeEffects}/>
                          )
                      })}
                  </div>
              </AccordionItem>

          </Accordion>
          <ClearFilterButton clearAllFilters={handleClear}/>
      </Wrapper>
    )
}

export default SecondaryFilters;