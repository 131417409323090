import React, { useState, useEffect, useMemo, Fragment } from 'react';
import styled from 'styled-components';

import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

import {BsCheck} from 'react-icons/bs';


import throttle from 'lodash.throttle';


const { Handle } = Slider;

const MOBILE_WIDTH = '900px';

const Wrapper = styled.div`
  height: 100%;
  width: 17.5vw;
  @media (max-width: ${MOBILE_WIDTH}) {
    margin: 0 10px;
    width: calc(100% - 20px);
  }

  .rc-slider-handle {
    &.rc-slider-handle-1 {
      &:active {
        box-shadow: 0 0 5px var(--darkgreen) !important;
        outline: none !important;
      }
      &:focus {
        box-shadow: 0 0 5px var(--darkgreen) !important;
        outline: none !important;
      }
    }
    &.rc-slider-handle-2 {
      &:active {
        box-shadow: 0 0 5px var(--darkgreen) !important;
        outline: none !important;
      }
      &:focus {
        box-shadow: 0 0 5px var(--darkgreen) !important;
        outline: none !important;
      }
    }
  }

  padding-bottom: ${(props) => (props.mobile ? '30px' : '0px')};
`;


const UnitFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .label{
    display: inline-block;
    font-family: IBM Plex Mono Regular;
    font-size: 19px;
    opacity: 0.8;
    padding-bottom: 5px;
    &.mg{
      padding-right: 5px;
    }
  }

  .pretty{
    margin-right: 0px !important;
  }
`;

const TextInputsSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2px 12px 10px;

  .inputGroup {
    min-width: 43%;
    display: flex;
    justify-content: center;

    @media (max-width: ${MOBILE_WIDTH}) {
      min-width: 40%;
      padding-bottom: 20px;
    }
  }

  .divider {
    width: 100%;
    margin: 0px 3px;
    padding-top: 14px;
    display: flex;
    justify-content: center;
    hr {
      width: 100%;
      color: black;
      opacity: 0.3;
      background-color: black;
      border: 0px;
      height: 1.5px;
    }
  }

  .prefix {
    display: ${(props) => (props.prefix ? 'inline-flex' : 'none')};
    align-items: center;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    border: 2px solid var(--darkgreen);
    border-right: 0px;
    background-color: var(--green);

    padding: 10px 5px;
  }
  .sufix {
    display: ${(props) => (props.sufix ? 'block' : 'none')};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    border: 2px solid var(--darkgreen);
    border-left: 0px;
    background-color: var(--green);

    padding: 10px 5px;
  }

  input[type='text'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input {
    box-sizing: border-box;
    max-width: 80%;
    font-family: IBM Plex Mono Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    padding: 10px 5px;

    border: 2px solid var(--darkgreen);
    outline: none;

    background-color: var(--green);

    border-right: 3px solid red;
    border-left: ${(props) =>
      props.prefix ? '0px solid rgba(110, 111, 114, 0.25)' : '2px solid var(--darkgreen)'};
    border-right: ${(props) =>
      props.sufix ? '0px solid rgba(110, 111, 114, 0.25)' : '2px solid var(--darkgreen)'};
    //border-radius: ${(props) => (props.prefix ? '0px 10px 10px 0px' : '10px 0px 0px 10px')};
  }

  @media (max-width: ${MOBILE_WIDTH}){
    .divider {
        width: 100%;
        margin: 0 7px;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ApplyDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: ${MOBILE_WIDTH}){
    margin-top: 15px;
  }
`;

const ApplyButton = styled.button`
  background-color: var(--darkgreen);
  color: white;
  border-radius: 30px;
  border: none;
  padding: 5px 35px;
  margin: 5px 0px;
  font-family: IBM Plex Mono Medium;
  font-size: 17px;
  font-weight: 900;

  grid-column: 2;

  &:hover{
    cursor: pointer;
    background-color: #568278;
  }

  @media (max-width: ${MOBILE_WIDTH}){
    padding: 10px 50px;
  }
`;

const ApplyCheck = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  grid-column: 3;
`;

const ClearButton = styled.div`
  font-family: IBM Plex Mono Medium;
  font-size: 14px;
  color: black;


  &:hover{
    text-decoration: underline;
    cursor: pointer;
  }
`;

const HandleWrapper = styled.div`
    background: var(--darkgreen);
`;

const initialMinMax = [0, 300]

export default function PriceSliderFilter({
  prefix,
  sufix,
  mobile,
  hideslider,
  onChangePrice,
}) {
    const [minMax, setMinMax] = useState(initialMinMax);

    const priceHandler = () => {
      onChangePrice(minMax)
    }

    const handle = props => {
        const { value, dragging, index, ...restProps } = props;
        return (
            <HandleWrapper>
                <Handle value={value} {...restProps} />
            </HandleWrapper>
        );
    };


    return (
            <Wrapper mobile={mobile}>
                <TextInputsSection prefix={prefix} sufix={sufix}>
                <div className="inputGroup">
                    <span className="prefix">{prefix}</span>
                    <input
                    type="text"
                    value={minMax[0]}
                    className="minInputText"
                    onChange={(event) => {
                        let newArr = [...minMax];
                        newArr[0] = event.target.value;
                        setMinMax(newArr);
                    }}
                    />
                    <span className="sufix">{sufix}</span>
                </div>
                <div className="divider">
                    <hr />
                </div>
                <div className="inputGroup">
                    <span className="prefix">{prefix}</span>
                    <input
                    type="text"
                    value={minMax[1]}
                    onChange={(event) => {
                        let newArr = [...minMax];
                        newArr[1] = event.target.value;
                        setMinMax(newArr);
                    }}
                    className="maxInputText"
                    />
                    <span className="sufix">{sufix}</span>
                </div>
                </TextInputsSection>

                { !hideslider && 
                <Range
                  value={minMax}
                  onChange={(minMax) => {
                    setMinMax([...minMax]);

                }}
                onAfterChange={priceHandler}
                min={0}
                max={300}
                railStyle={{
                    background: 'rgba(68, 105, 97, 0.4)',
                    height: '2px',
                }}
                trackStyle={[
                    {
                    backgroundColor: 'var(--green)',
                    },
                ]}
                handleStyle={[
                    {
                    borderRadius: 0,
                    borderColor: 'var(--darkgreen)',
                    backgroundColor: 'var(--darkgreen)',
                    marginTop: mobile ? '-12px' : '',
                    height: mobile ? '30px' : '',
                    width: mobile ? '30px' : '',
                    },
                    {
                    borderRadius: 0,
                    borderColor: 'var(--darkgreen)',
                    backgroundColor: 'var(--darkgreen)',
                    marginTop: mobile ? '-12px' : '',
                    height: mobile ? '30px' : '',
                    width: mobile ? '30px' : '',
                    }
                ]}
                handle={handle}
                />
                }
                    {/*<Buttons>*/}
                    {/*    <ApplyDiv>*/}
                    {/*    <ApplyButton*/}
                    {/*        onClick={ () => {*/}
                    {/*            priceHandler(minMax)*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*    Apply*/}
                    {/*    </ApplyButton>*/}
                    {/*    </ApplyDiv>*/}
                    {/*    <ClearButton */}
                    {/*    onClick={handlerClear}*/}
                    {/*    >*/}
                    {/*    Clear*/}
                    {/*    </ClearButton>*/}
                    {/*</Buttons>*/}
        </Wrapper>
  )
}
