import React, { useContext, useState } from 'react'
import styled from 'styled-components';
import Select from "react-select"
import { FiltersContext } from "../../../contexts/filters"

//Views

const sortOptions = [
	{
		label: "Relevance",
		value: "POPULAR",
		rqObj: {direction: "DESC", key: "POPULAR"},
	},
	{
		label: "Lowest Price",
		value: "LOW_PRICE",
		rqObj: {direction: "ASC", key: "PRICE"},
	},
	{
		label: "Highest Price",
		value: "HIGH_PRICE",
		rqObj: {direction: "DESC", key: "PRICE"},
	},
	{
		label: "Name (A-Z)",
		value: "NAME",
		rqObj: {direction: "ASC", key: "NAME"},
	},
]

export default function Sort() {
	// const [sort, setBrandId] = useState()
	const {onChangeSort} = useContext(FiltersContext)

	return (
		<SortWrapper>
			<div className="label">SORT BY</div>
			<Select
				placeholder={"Relevance"}
				classNamePrefix="react-select-filter"
				onChange={e => onChangeSort(e.rqObj)}
				options={sortOptions}/>
		</SortWrapper>
	)
}

const SortWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;

  .label {
    display: inline-block;
    font-family: IBM Plex Mono Bold;
    color: var(--darkgreen);
    font-size: 16px;
  }

  .sortSelect {
    display: inline-block;
    min-width: 175px;
    font-family: IBM Plex Mono Regular;
    font-size: 12px;
    border-radius: 15px;
    background: none;
  }

  .react-select-filter {
    &__control {
	    margin-left: 5px;
      min-width: 170px;
      border: none !important;
	    box-shadow: none;
    }
	  &__indicator {
		  color: var(--darkgreen);
		  &-separator {
        display: none;
		  }
	  }
  }
`;