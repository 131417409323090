import React from 'react';
import styled from 'styled-components';


//Filter Components
import CategoryFilters from '../../elasticsearch/menu/CategoryFilters';
import SecondaryFilters from '../../elasticsearch/menu/SecondaryFilters';
import {MOBILE_BREAKPOINT} from '../../../styles/breakpoints';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    padding: 0 20px;
    
    @media (max-width: ${MOBILE_BREAKPOINT}){
        display: none;
    }
`;
//
export default function MenuDesktopFilter({prerenderdata, cmsCategoryData}){
    return(
        <Wrapper>
            <CategoryFilters 
                prerenderCategory={prerenderdata.category}
                prerenderSubcategory={prerenderdata.subcategory}
                cmsCategoryData={cmsCategoryData}
            />
            <SecondaryFilters prerenderCategory={prerenderdata.category}/>
        </Wrapper>
    )
}