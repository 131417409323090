import React from 'react';
import styled from 'styled-components'

import { MOBILE_BREAKPOINT } from '../../../../styles/breakpoints';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .clear{
        font-weight: 700;

        &:hover{
            cursor: pointer;
        }
    }

    .title{
        font-family: Druk Wide Bold;
        text-transform: uppercase;
        text-align: center;
    }

    @media(max-width: ${MOBILE_BREAKPOINT})
    {
        .title{
            width: 85%;
            font-size: 24px;
        }
    }
`;

const ImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 25px;
    background: rgba(162, 199, 179, 0.35);
    border-radius: 100px;

    margin: 3vh 0;
`

const TextWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    width: 40%;

    text-align: center;
    font-weight: 600;

    p{
        white-space: nowrap;
    }

    .clear{
        font-weight: 700;
    }

    @media(max-width: ${MOBILE_BREAKPOINT})
    {
        font-size: 14px;

        width: 100%;

        p{
            width: 90%;
        }
    }
`

export default function NoResultsView({ clearFilters })
{

    const handleRemoveAll = () => {
        clearFilters();
    }

    return (
        <Wrapper>
            <h1 className="title">No Results Found</h1>

            <ImgWrapper>
                <img
                    src="/no-result.svg"
                />
            </ImgWrapper>

            <TextWrapper>
                <p>
                Try adjusting your search or&nbsp;
                </p>

                <p
                    className="clear"
                    onClick={handleRemoveAll}
                >
                Clear Filters
                </p>

                <p>
                &nbsp;to find what you’re looking for.
                </p>
            </TextWrapper>
            {/* <p className="clear">Clear Filters</p> */}
            {/* return <p className="clear" onClick={handleRemoveAll}>Clear Filters</p> */}
        </Wrapper>
    )
}